<template>
  <BaseLayout>
    <template #header>
      <h2>Screening IMLTD</h2>
      <div class="d-flex justify-content-between">
        <div class="row align-items-center">
          <p class="hp-p1-body mb-0 mx-3 text-black">
            Semua ({{ table.total }})
          </p>
          <b-form-group
            label="Filter Berdasarkan Status"
            label-for="filter-status-screening"
            class="mb-0"
          >
            <b-form-select
              id="filter-status-screening"
              v-model="filter.status"
              :options="optionsStatus"
              @input="handleStatusInput"
            />
          </b-form-group>
        </div>
        <div class="w-15 align-items-center">
          <div class="position-relative">
            <b-input-group class="hp-input-group-search custom-input-height">
              <b-form-input
                  v-model="table.search"
                type="search"
                placeholder="Cari Berdasarkan Kode"
                class="custom-input-height search-input"
                @input="debounceSearch"
              />
              <i class="ri-search-line remix-icon text-primary search-icon" />
            </b-input-group>
          </div>
        </div>
      </div>
    </template>
    <template #content>
      <b-table
        style="overflow: auto; white-space: nowrap"
        :items="table.items"
        :fields="fields"
        :busy.sync="table.loading"
        responsive="sm"
        show-empty
      >
        <template #cell(status)="row">
          <b-badge
            :variant="row.item.status === 'non-reaktif' ? 'success' : row.item.status === 'reaktif' ? 'danger' : row.item.status === 'greyzone' ?  'light': '' "
          >
            {{ row.item.status === 'non-reaktif' ? 'Non Reaktif' : row.item.status === 'reaktif' ? 'Reaktif' :  row.item.status === 'greyzone' ? 'Grey Zone' : '' }}
          </b-badge>
        </template>

        <template #cell(syphilis)="row">
          <p>{{ row.item.syphilis || 'Neg' }}</p>
        </template>

        <template #cell(hepatitis_b)="row">
          <p>{{ row.item.hepatitis_b || 'Neg' }}</p>
        </template>

        <template #cell(hepatitis_c)="row">
          <p>{{ row.item.hepatitis_c || 'Neg' }}</p>
        </template>

        <template #cell(hiv_aids)="row">
          <p>{{ row.item.hiv_aids || 'Neg' }}</p>
        </template>

        <template #table-busy>
          <div class="hp-p1-body text-center my-2">
            <b-spinner class="align-middle mr-8" />
            <strong>Memuat...</strong>
          </div>
        </template>

        <template #empty>
          <p class="hp-p1-body text-center">
            Tidak Ada Data
          </p>
        </template>
      </b-table>
      <b-pagination
        v-model="table.currentPage"
        class="mb-3"
        align="right"
        :per-page="table.perPage"
        :total-rows="table.total"
        @input="changePage"
      />
    </template>
  </BaseLayout>
</template>
<script>
import {
  BFormSelect, BFormGroup, BInputGroup, BFormInput, BTable, BSpinner, BBadge, BPagination,
} from 'bootstrap-vue'
import ScreeningAPI from '@/api/screening/screeningAPI'
import tableDataMixins from '@/mixins/tableDataMixins'

export default {
  name: 'ListScreening',
  components: {
    BBadge,
    BSpinner,
    BFormSelect,
    BFormGroup,
    BInputGroup,
    BFormInput,
    BTable,
    BPagination,
  },
  mixins: [tableDataMixins],
  data() {
    return {
      // data untuk filter status skrining : reaktif dan non-reaktif
      optionsStatus: [
        { value: '', text: 'Pilih Opsi Status' },
        { value: 'reaktif', text: 'Reaktif' },
        { value: 'non-reaktif', text: 'Non-Reaktif' },
        { value: 'greyzone', text: 'Grey Zone' },
      ],
      // untuk sortir dan filtering
      selectedBy: {
        status: null,
      },
      filter: {
        status: '',
      },
      // fields untuk table screening
      fields: [
        {
          label: 'No',
          key: 'id',
          sortable: true,
        },
        {
          label: 'Kode Kantong',
          key: 'barcode',
        },
        {
          label: 'Tanggal Pemeriksaan',
          key: 'created_at',
        },
        {
          label: 'Syphilis',
          key: 'syphilis',
        },
        {
          label: 'HBsAg',
          key: 'hepatitis_b',
        },
        {
          label: 'HCV',
          key: 'hepatitis_c',
        },
        {
          label: 'HIV',
          key: 'hiv_aids',
        },
        {
          label: 'Reaktif',
          key: 'status',
        },
        {
          label: 'Nama Petugas',
          key: 'officer_name',
        },
      ],
    }
  },
  mounted() {
    this.fetchData()
  },
  methods: {
    async fetchData() {
      try {
        this.table.loading = true

        const params = {
          page: this.table.currentPage,
          per_page: this.table.perPage,
          search: this.table.search,
          status: this.selectedBy.status ? this.selectedBy.status : undefined,
        }

        const { data: response } = await ScreeningAPI.getAll({
          params,
        })

        this.table.items = response.data.data
        this.table.total = response.data.total
      } catch (e) {
        console.error(e)
      } finally {
        this.table.loading = false
      }
    },
    // menangani filter berdasarkan status
    handleStatusInput(selectedValue) {
      this.selectedBy.status = selectedValue

      this.fetchData()
    },
    // fungsi untuk paginations dan search
    changePage(page) {
      this.table.currentPage = page
      this.fetchData()
    },
    debounceSearch() {
      clearTimeout(this.table.debounceTimer)
      this.table.debounceSearch = setTimeout(() => {
        this.onChangeSearchTable()
      }, 500)
    },
    onChangeSearchTable() {
      this.fetchData()
    },
  },
}
</script>

<style scoped>
    /* kostumisasi ukuran dari input search */
    .custom-select-height,
    .custom-input-height {
        height: 38px;
    }

    .custom-button-height {
        height: 100%;
    }

    .hp-input-group-search {
        display: flex;
        align-items: center;
    }

    /* posisi relatif untuk menempatkan icon pencarian kedalam input */
    .position-relative {
         position: relative;
    }

    .search-input {
        width: 100%;
        padding-left: 30px;
        height: 38px;
        border: 1px solid #ced4da;
        border-radius: 5px;
    }

    .search-icon {
        position: absolute;
        left: 10px;
        top: 50%;
        transform: translateY(-50%);
        font-size: 16px;
        color: #007bff;
    }
</style>
